<template>
  <div id="misc">
    <div class="page-title text-center justify-center px-5">
      <div class="misc-character d-flex justify-center">
        <v-img
          v-show="isLoading"
          max-width="100"
          margin="100"
          src="@/assets/images/misc/loading-spinner-dark.gif"
        ></v-img>
        <div
          v-show="!isLoading"
        >
          {{ message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiAlert } from '@mdi/js'
import * as QuasrHelper from '@quasr-io/helper'
import axios from 'axios'
import IdTokenVerifier from 'idtoken-verifier'
import store from '../store'

export default {
  /**
   * setup
   */
  setup() {
    const ENV = QuasrHelper.getEnv()
    const API_ENV = QuasrHelper.getApiEnv()
    const QUASR_ENV = QuasrHelper.getTenantAndClient()
    const loginStatus = QuasrHelper.checkLoginStatus(QUASR_ENV.tenant_id)
    const uiMode = document.location.hostname.startsWith('account') ? 'account' : 'admin'
    const isLoading = true
    const message = ''

    const verifier = new IdTokenVerifier({
      issuer: API_ENV === 'prod' ? `https://${QUASR_ENV.tenant_id}.api.quasr.io` : `https://${QUASR_ENV.tenant_id}.api-${API_ENV}.quasr.io`,
      audience: QUASR_ENV.client_id,
      jwksURI: API_ENV === 'prod' ? `https://${QUASR_ENV.tenant_id}.api.quasr.io/.well-known/jwks.json` : `https://${QUASR_ENV.tenant_id}.api-${API_ENV}.quasr.io/.well-known/jwks.json`,
    })

    return {
      ENV,
      API_ENV,
      QUASR_ENV,
      uiMode,
      loginStatus,
      message,
      isLoading,
      verifier,
    }
  },

  /**
   * data
   */
  data() {
    return {
      icons: {
        mdiAlert,
      },
    }
  },

  /**
   * created
   */
  async created() {
    this.checkUserStatus()
  },
  methods: {

    /**
     * checkUserStatus
     */
    async checkUserStatus() {
      // get "state" and "code" from query params, if any
      const paramsString = document.location.search
      const searchParams = new URLSearchParams(paramsString)

      if (searchParams.get('code')) {
        try {
          const accessToken = await this.exchangeCodeForToken(searchParams.get('code'))
          if (accessToken && searchParams.get('id_token')) {
            this.verifyIdToken(searchParams.get('id_token'))
            this.message = 'Authentication successful. You can close this browser window and return to the Quasr Account UI.'
            window.opener.loginCallback({ success: true, message: this.message })
          }
        } catch (err) {
          this.message = 'Authentication failed. '.concat(err)
          window.opener.loginCallback({ success: false, message: this.message })
        } finally {
          this.isLoading = false
          window.close()
        }
      } else if (searchParams.get('error')) {
        this.isLoading = false
        this.message = searchParams.get('error')
        window.opener.loginCallback({ success: false, message: this.message })
        store.message = this.message
        this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/security#error=${this.message}`)

        // this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/enrollments/${localStorage.getItem('enrollment')}#error=${this.message}`)

        window.close()
      } else if (searchParams.get('id') && searchParams.get('input')) {
        this.enrollmentFinalization(searchParams.get('id'), searchParams.get('input'))
      }
    },

    /**
     * enrollmentFinalization
     */
    async enrollmentFinalization(id, input) {
      const response = await axios({
        method: 'POST',
        url: this.API_ENV === 'prod' ? `https://${this.QUASR_ENV.tenant_id}.api.quasr.io/factors` : `https://${this.QUASR_ENV.tenant_id}.api-${this.API_ENV}.quasr.io/factors`,
        data: [{
          id,
          input,
        }],
        headers: {
          Authorization: `Bearer ${this.loginStatus.access_token}`,
        },
      })

      // const { result } = response.data
      // const { enrollment } = response.data
      const { error } = response.data
      this.isLoading = false
      this.message = error || 'Authentication successful. You can now close this browser tab and return to the Quasr Account Administration UI.'
      window.opener.loginCallback({ success: !error, message: this.message })
      window.close()
    },

    /**
     * exchangeCodeForToken
     */
    async exchangeCodeForToken(code) {
      const response = await axios({
        method: 'POST',
        url: this.API_ENV === 'prod' ? `https://${this.QUASR_ENV.tenant_id}.api.quasr.io/oauth2/token` : `https://${this.QUASR_ENV.tenant_id}.api-${this.API_ENV}.quasr.io/oauth2/token`,
        data: {
          client_id: this.QUASR_ENV.client_id,
          grant_type: 'authorization_code',
          code_verifier: sessionStorage.getItem(`code_verifier.${this.QUASR_ENV.tenant_id}`),
          code,
        },
      })
      const { access_token } = response.data

      // const { id_token } = response.data
      // const { refresh_token } = response.data

      return access_token
    },

    /**
     * verifyIdToken
     */
    async verifyIdToken(idToken) {
      try {
        this.verifier.verify(idToken, undefined, error => {
          if (error) {
            QuasrHelper.log(error)
          }

          this.message = 'Authentication successful. You can close this browser window and return to the Quasr User Portal.'
          window.opener.loginCallback({ success: true, message: this.message })
        })
      } catch (err) {
        this.message = 'Authentication failed. '.concat(err)
        window.opener.loginCallback({ success: false, message: this.message })
      } finally {
        this.isLoading = false
        window.close()
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/misc.scss';
</style>
